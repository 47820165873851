@import url(https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&family=Pangolin&family=Patrick+Hand&display=swap);
@font-face {
  font-family: 'Pangolin';
  src: local('Pangolin'), url(/static/media/Pangolin-Regular.7810feee.ttf) format('ttf');
}

body {
  background-color: #7979f3;
  margin: 0;
}

h1,
h2 {
  font-weight: 700;
  font-family: 'Pangolin', cursive;
}

p,
ul,
li {
  font-family: 'Raleway', sans-serif;
}

ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}

ul.header {
  background-color: #111;
  padding: 0;
}

ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}

.content {
  background-color: #FFF;
  padding: 10px;
}

.content h2 {
  padding: 0;
  margin: 0;
}

.content li {
  margin-bottom: 10px;
}

input[type=text] {
  width: 30%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

form {
  padding: 5px;
}

label {
  font-family: 'Open Sans', sans-serif;
  padding: 5px;
}
